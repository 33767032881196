import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23beb8ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stream-container" }
const _hoisted_2 = { class: "control-bar" }
const _hoisted_3 = {
  key: 4,
  class: "inline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aVideo = _resolveComponent("aVideo")!
  const _component_aButton = _resolveComponent("aButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_aVideo, {
      id: _ctx.pipeline.id
    }, null, 8, ["id"]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isRunning)
        ? (_openBlock(), _createBlock(_component_aButton, {
            key: 0,
            "btn-event": "btn-start",
            class: "video-button play push",
            onBtnStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startStream()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Start ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isRunning)
        ? (_openBlock(), _createBlock(_component_aButton, {
            key: 1,
            "btn-event": "btn-stop",
            class: "video-button pause push",
            onBtnStop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.stopStream()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Stop ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_aButton, {
            key: 2,
            "btn-event": "btn-start-pipeline",
            class: "video-button",
            onBtnStartPipeline: _cache[2] || (_cache[2] = ($event: any) => (_ctx.mStartPipeline()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Start Pipeline (you shouldn't need to do this) ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_aButton, {
            key: 3,
            "btn-event": "btn-stop-pipeline",
            class: "video-button",
            onBtnStopPipeline: _cache[3] || (_cache[3] = ($event: any) => (_ctx.mStopPipeline()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Stop Pipeline (you probably don't want to do this) ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isAdmin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " active: " + _toDisplayString(_ctx.pipeline.active) + " listeners: " + _toDisplayString(_ctx.pipeline.listeners), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}